import * as D3 from "d3"

export default (selection, props) => {
    const {
        ascending,
        data,
        width,
        height,
        margin,
        fillColor,
        tooltipClass,
        valuesAxisFormat,
        splitAxisOnSpace,
        isHorizontal,
        mobile
    } = props;

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const keys = Object.keys(data);
    const values = keys.map(k => data[k]);

    const sortedKeys = [...keys].sort((a, b) => ascending ? data[a] - data[b] : data[b] - data[a]);
    const sortedValues = [...values].sort((a, b) => ascending ? a - b : b - a);

    const t = selection.transition()
        .duration(750);

    // console.log(ascending);
    // console.log(sortedValues);
    // console.log(valuesScale.domain());
    // console.log(valuesScale.range());

    /*******************
     *    Keys axis    *
     *******************/
    const keysScale = D3.scaleBand()
        .domain(sortedKeys)
        .range([0, isHorizontal ? innerHeight : innerWidth])
        .paddingInner(0.1)
        .paddingOuter(0);

    const spaceFn = t => {
        if (!splitAxisOnSpace) { return; }
        t.each(function () {
            let self = D3.select(this);
            let s = self.text().split(" ");
            if (s.length === 0) { return; }
            self.text("");
            s.forEach(text => {
                self.append("tspan")
                    .attr("x", 0)
                    .attr("dy", ".8em")
                    .text(text);
            })
        });
    }

    const kAxisFn = selection => {
        selection
            .call(isHorizontal ? D3.axisLeft(keysScale) : D3.axisBottom(keysScale))
            .attr("transform", isHorizontal ? `translate(${margin.left - 2},${ascending ? 0 : margin.top})` : `translate(${ascending ? 0 : margin.left - 2},${height - margin.bottom + 2})`)
            .selectAll(".keysAxis .tick text")
            .call(spaceFn)
    };

    selection.selectAll(".keysAxis")
        .data([null])
        .join(
            enter => enter.append("g")
                .attr("class", "keysAxis")
                .call(kAxisFn),
            update => update.transition(t)
                .call(kAxisFn)
        );

    /*********************
     *    Values axis    *
     *********************/
    const valuesScale = D3.scaleLinear()
        .domain([ascending ? sortedValues[sortedValues.length - 1] : sortedValues[0], 0])
        .range([isHorizontal ? innerWidth : 0, isHorizontal ? 0 : innerHeight])
        .nice();

    let valuesFn;
    if (isHorizontal) {
        valuesFn = ascending ?
            D3.axisBottom(valuesScale) :
            D3.axisTop(valuesScale);
    } else {
        valuesFn = ascending ?
            D3.axisRight(valuesScale) :
            D3.axisLeft(valuesScale);
    }
    valuesFn = valuesFn.tickFormat(D3.format(valuesAxisFormat))

    const vAxisFn = selection => {
        selection
            .attr("transform", isHorizontal ? `translate(${margin.left}, ${ascending ? height - margin.bottom + 2: margin.top - 2})` : `translate(${ascending ? width - margin.right + 2 : margin.left - 2},${margin.top})`)
    };

    selection.selectAll(".values-axis")
        .data([null])
        .join(
            enter => enter.append("g")
                .attr("class", "values-axis")
                .call(valuesFn)
                .call(vAxisFn),
            update => update.transition(t)
                .call(valuesFn)
                .call(vAxisFn)
        );

    /**************
     *    Bars    *
     **************/
    let tooltipDiv = D3.select(`.${tooltipClass}`);
    const setupMouseHandler = selection => {
        selection
            .on("mouseover", () => {
                let s = tooltipDiv.transition()
                    .duration(200)
                    .style("opacity", .9);
                if (mobile) {
                    s.transition()
                    .duration(3000)
                    .style("opacity", 0)
                }
            })
            .on("mousemove", function (d, i) {
                tooltipDiv.html(`${sortedValues[i].toLocaleString()}`)
                    .style("left", (D3.event.pageX) + "px")
                    .style("top", (D3.event.pageY - 28) + "px");
            })
            .on("mouseout", function () {
                tooltipDiv.transition()
                    .duration(500)
                    .style("opacity", 0)
            });
    };

    const setupRects = selection => {
        selection
            .attr(isHorizontal ? "y" :     "x",      d      => keysScale(d))
            .attr(isHorizontal ? "x" :     "y",      (d, i) => isHorizontal ? 0 : margin.top + valuesScale(sortedValues[i]))
            .attr(isHorizontal ? "width" : "height", (d, i) => (isHorizontal ? 0 : innerHeight) + (isHorizontal ? 1 : -1) * valuesScale(sortedValues[i]));
    }

    const rectsFn = selection => {
        selection
            .attr("transform", isHorizontal ? `translate(${margin.left}, ${ascending ? 0 : margin.top})` : `translate(${ascending ? 0 : margin.left},0)`)
            .attr(isHorizontal ? "height" : "width", keysScale.bandwidth())
            .call(setupRects)
    }

    selection.selectAll(".myRect")
        .data(sortedKeys, d => d)
        .join(
            enter => enter.append("rect")
                .attr("class", "myRect")
                .attr("fill", (d, i) => (typeof fillColor === "string") ? fillColor : fillColor[d])
                .call(rectsFn)
                .call(setupMouseHandler),
            update => update
                .call(setupMouseHandler)
                .call(u => u.transition(t)
                    .call(rectsFn))
        );
}