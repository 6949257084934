import React, { useState, useRef } from "react"
import * as D3 from "d3"
import { Dropdown } from "semantic-ui-react"
import SEO from "../../../components/seo"
import useContainerDimensions from "../../../components/useContainerDimensions"
import barChart from "../../../components/barChart"
import styles from "./water-footprint.module.css"
import "../../general.css"
import FootprintData from "../../../data/water-footprint.json"
import { PlantsGreenColor, AnimalsRedColor } from "../../../defines.js"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const Title = "Water footprint comparison for food products";

const getOptionsList = () =>
    [
        {
            key: "Calories (l/kcal)",
            value: "Calories (l/kcal)",
            text: "Calories (l/kcal)"
        },
        {
            key: "Weight (l/kg)",
            value: "Weight (l/kg)",
            text: "Weight (l/kg)"
        },
        {
            key: "Protein (l/g)",
            value: "Protein (l/g)",
            text: "Protein (l/g)"
        },
        // {
        //     key: "Fat (l/g)",
        //     value: "Fat (l/g)",
        //     text: "Fat (l/g)"
        // }
    ];

const WaterFootprint = ({ mobile }) => {
    let GraphAspectRatio = 4 / 3;
    if (mobile) {
        GraphAspectRatio = 3 / 4;
    }

    const [selectedOption, setSelectedOption] = useState(getOptionsList()[0].key);

    const onOptionChanged = (event, result) => {
        let { value } = result || event.target;
        setSelectedOption(value);
    }

    const graphDivRef = useRef();
    const { width } = useContainerDimensions(graphDivRef);
    const svgWidth = Math.max((width - 10), 0);
    const svgHeight = Math.max((width - 10) / GraphAspectRatio, 0);
    const margin = {
        left: mobile ? 47 : 0,
        top: mobile ? 25 : 20,
        right: mobile ? 20 : 35,
        bottom: mobile ? 5 : 25
    };
    const optionToDataKey = {
        "Calories (l/kcal)": "calorie",
        "Weight (l/kg)": "weight",
        "Protein (l/g)": "protein",
        "Fat (l/g)": "fat"
    }
    const selectedOptionKey = optionToDataKey[selectedOption];
    const renderProducts = () => {
        if (typeof document === "undefined") {
            return;
        }
        const productsSvg = D3.select(".products");
        let dataObj = {};
        let colors = {};
        FootprintData.plant.forEach(function (productData) {
            dataObj[productData.item] = productData[selectedOptionKey];
            colors[productData.item] = PlantsGreenColor;
        });
        FootprintData.animal.forEach(function (productData) {
            dataObj[productData.item] = productData[selectedOptionKey];
            colors[productData.item] = AnimalsRedColor;
        });
        let barChartG = productsSvg.select(".barChartG");
        if (barChartG.empty()) {
            barChartG = productsSvg.append("g");
            barChartG.attr("class", "barChartG");
        }
        barChartG.call(barChart, {
            ascending: !mobile,
            data: dataObj,
            width: svgWidth,
            height: svgHeight,
            margin: margin,
            fillColor: colors,
            tooltipClass: styles.tooltip,
            valuesAxisFormat: ".2s",
            isHorizontal: mobile,
            mobile
        });
    };

    renderProducts();
    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div className={styles.tooltip} style={{ opacity: 0 }} />
                    <div className={`citation ${styles.textAlignCenter}`} style={{ paddingTop: 0 }}>
                        (The units in this visualization are liters of water needed per selected category)
                </div>
                    <div className={`${styles.dropDown} ${styles.textAlignCenter}`} style={{ marginTop: "10px" }}>
                        <Dropdown
                            placeholder="Weight (l/kg)"
                            selection
                            options={getOptionsList()}
                            value={selectedOption}
                            compact
                            basic
                            onChange={onOptionChanged}
                        />
                    </div>
                    <div className="FlexGrow " ref={graphDivRef} style={{ textAlign: "center", marginTop: 20 }}>
                        <svg className="products" width={`${svgWidth}px`} height={`${svgHeight}px`} />
                    </div>
                    <div className="citation" style={{ marginTop: "20px" }}>
                        Data source: <a href="https://waterfootprint.org/en/water-footprint/product-water-footprint/water-footprint-crop-and-animal-products/">
                            Water footprint network</a><br />
                    </div>
                </div>
            </div>
        </>
    );
}

export default WaterFootprint;
